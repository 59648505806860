import React, {useEffect} from 'react';
import {setActivePanel} from "../store/mainReducer";
import {useDispatch} from "react-redux";

const Game1 = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
        setTimeout(()=> {
            dispatch(setActivePanel('game1result'))
        },3000)
    },[])

    return (
        <div className="container main-screen ava-game">
            <a href="#" className="main-screen__logo link_disabled">
                <img src="images/escape-logo-black.png" width="208" height="64" alt=""/>
            </a>
            <p className="caption caption_size_big caption_color_black ava-game__caption">Твоя Легенда</p>
            <div className="scanner-wrapper">
                <div className="scanner-block ava-game__scanner-block">
                <img src="images/person-placeholder.svg" width="300" height="300" alt=""/>
                </div>
            </div>
            <button onClick={()=>dispatch(setActivePanel('menu'))} className="button back-button main-screen__back-button">
                назад
            </button>
        </div>
    );
};

export default Game1;