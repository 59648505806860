import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const Prizes = () => {
    const dispatch = useDispatch()
    return (
        <div className="container main-screen prizes">
            <a href="#" className="main-screen__logo link_disabled">
                <img src="images/escape-logo-white.png" width="208" height="64" alt=""/>
            </a>
            <p className="caption caption_size_big caption_color_white main-screen__caption prizes__caption">Награды</p>
            <img className="prizes__hero-pic" src="images/woman.png" width="144" height="190" alt=""/>
            <div className="prizes__codes-block">
                <div className="code-block">
                    <i></i>
                    {/*<img src="images/code-mark.svg" width="57" height="20" alt=""/>*/}
                        <div>
                            <p><span style={{textTransform:"none", fontWeight:"700", fontSize:"26px"}}>x</span>10 кодов на монеты Apex</p>
                            <span>будут разыграны случайно среди всех,<br/>
                        кто поделится результатом теста на своей стене</span>
                        </div>
                </div>
                <div className="code-block">
                    {/*<img src="images/code-mark.svg" width="57" height="20" alt=""/>*/}
                    <i></i>
                        <div>
                            <p><span style={{textTransform:"none", fontWeight:"700", fontSize:"26px"}}>x</span>15 кодов на монеты Apex</p>
                            <span>получат игроки, набравшие больше всего очков<br/>
                        на Стрельбище по итогам всех перестрелок
                        </span>
                        </div>
                </div>
            </div>
            <button onClick={()=>dispatch(setActivePanel('menu'))} className="button back-button main-screen__back-button">
                назад
            </button>
        </div>
    );
};

export default Prizes;