import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel, zeroCurScore} from "../store/mainReducer";

const AboutGame2 = () => {
    const dispatch = useDispatch()
    useEffect(() => {dispatch(zeroCurScore())}, [])
    return (
        <div className="container main-screen scout-game">
            <a href="#" className="main-screen__logo link_disabled">
                <img src="images/escape-logo-white.png" width="208" height="64" alt=""/>
            </a>
            <p className="caption caption_size_big caption_color_white main-screen__caption scout-game__caption">Разведданные</p>
            <p className="text text_color_white scout-game__description">Перед выходом на поле боя проверь свои знания и
                участвуй в розыгрыше<br/>
                <b>10 кодов на монеты Apex</b></p>
            <div className="scout-game__medal-block medal-block">
                <p className="text text_color_white">
                    За прохождение теста
                    <span>ты получишь 1 медаль</span>
                </p>
                <img width="70" height="67" src="images/medal.png" alt=""/>
            </div>
            <button onClick={() => dispatch(setActivePanel('game2'))}
                    className="scout-game__main-button button button_color_red">начать тест
            </button>
            <button onClick={() => dispatch(setActivePanel('menu'))}
                    className="button back-button main-screen__back-button">
                назад
            </button>
        </div>
    );
};

export default AboutGame2;