import React, {useEffect} from 'react';
import Init from "./panels/Init";
import {useDispatch, useSelector} from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import {setUser} from "./store/mainReducer";
import ServerConnect from "./service";
import Menu from "./panels/Menu";
import AboutGame1 from "./panels/AboutGame1";
import Game1 from "./panels/Game1";
import Game1Res from "./panels/Game1Res";
import AboutGame2 from "./panels/AboutGame2";
import Game2 from "./panels/Game2";
import Game2Res from "./panels/Game2Res";
import AboutGame3 from "./panels/AboutGame3";
import Prizes from "./panels/Prizes";

const App = () => {

  const dispatch = useDispatch()
  const activePanel = useSelector(state => state.main.activePanel)


  useEffect(() => {
    async function fetchData() {
      const user = await bridge.send('VKWebAppGetUserInfo');
      if (user) {
        console.log(user)
        return user;
      }
    }

    fetchData().then(data => get_user_info(data))

  }, [])

  async function get_user_info(user) {
    let response = await fetch(ServerConnect.api_url + '/api/user/' + user.id);
    if (response.status === 200) {
      let user_info = await response.json()
      dispatch(setUser(user_info))
    }
  }

  let content = []

  if (activePanel === 'init') {
    content.push(<Init/>)
  } else if (activePanel === 'menu') {
    content.push(<Menu/>)
  } else if (activePanel === 'about1game') {
    content.push(<AboutGame1/>)
  } else if (activePanel === 'game1') {
    content.push(<Game1/>)
  } else if (activePanel === 'game1result') {
    content.push(<Game1Res/>)
  } else if (activePanel === 'about2game') {
    content.push(<AboutGame2/>)
  } else if (activePanel === 'game2') {
    content.push(<Game2/>)
  } else if (activePanel ==='game2result') {
    content.push(<Game2Res/>)
  } else if (activePanel === 'about3game') {
    content.push(<AboutGame3/>)
  } else if (activePanel === 'prizes') {
    content.push(<Prizes/>)
  }

  return (
      <div className='app'>
        {content}
      </div>
  );
}

export default App;