import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {appCurScore, setActivePanel} from "../store/mainReducer";


const Game2 = () => {

    const data = {
        1: {
            text: <>Начнем с разминки. <nobr>На какой</nobr> карте расположен самый длинный трос для перемещения?</>,
            answer1: ['Каньон Кингс', false],
            answer2: ['Место бури', true],
            answer3: ['Край света', false],
            path:''
        },
        2: {
            text: <>Уже изучили карту “Место бури”? Что за точка <nobr>на скриншоте?</nobr></>,
            answer1: ['Антенна', false],
            answer2: ['Центр управления', false],
            answer3: ['Барометр', true],
            path:'images/question2.png'
        },
        3: {
            text: 'Допустим, вы хотите поохотиться на пауков на новой карте, но у вас мало времени. Как лучше поступить?',
            answer1: ['Сначала уничтожаем пауков, потом - гнезда', false],
            answer2: ['Сначала уничтожаем гнезда, потом - пауков', true],
            answer3: ['Скормить какую-нибудь ненужную легенду, чтобы задобрить', false],
            path:''
        },
        4: {
            text: <>Теперь об Эш, символе сезона “Побег”. Она — симулякр, <nobr>но что</nobr> случилось с ее настоящим телом?</>,
            answer1: ['Уничтожено на “Олимпе”', true],
            answer2: ['Разбросано по “Каньону Кингс”', false],
            answer3: ['Затянуто в черную дыру', false],
            path:''
        },
        5: {
            text: 'А как зовут крысу Эш?',
            answer1: ['Мэри', false],
            answer2: ['Бранти', false],
            answer3: ['У нее нет имени', true],
            path:''
        },
        6: {
            text: <>Одна из легенд никогда не простит предательства Эш. <nobr>Кто же это?</nobr></>,
            answer1: ['Хорайзон', true],
            answer2: ['Патфайндер', false],
            answer3: ['Ревенант', false],
            path:''
        },
        7: {
            text: 'Уже опробовали новый пистолет-пулемет C.A.R.? Сколько патронов вмещает его стартовый магазин?',
            answer1: ['20', true],
            answer2: ['24', false],
            answer3: ['30', false],
            path:''
        },
        8: {
            text: 'Теперь вопрос посложнее. На каких патронах отдача ПП C.A.R. меньше?',
            answer1: ['На легких', false],
            answer2: ['Одинакова', true],
            answer3: ['На тяжелых', false],
            path:''
        },
        9: {
            text: <>Вы наверняка знакомы с Несси: главной пасхалкой Apex. <nobr>А где</nobr> обитает сама Несси?</>,
            answer1: ['В океанах вокруг “Места бури”', false],
            answer2: ['Неподалеку от “Каньона Кингс”', true],
            answer3: ['Только в сердцах игроков', false],
            path:'images/question9.jpg'
        },
        10: {
            text: 'Сколько игрушечных Несси нужно настрелять на “Каньоне Кингс”, чтобы увидеть настоящую?',
            answer1: ['10', true],
            answer2: ['12', false],
            answer3: ['32', false],
            path:'images/question10.jpg'
        }
    }

    const dispatch = useDispatch()
    const [num,setNum] = useState(1)
    const [question, setQuestion] = useState({text:'', answer1:'', answer2:'', answer3:'', path:''})

    useEffect(()=>{setQuestion(data[num])},[num])

    const go = (e) => {
        let btn = Number(e.currentTarget.id)
        let isRight;
        if (btn === 1) {
            isRight = question.answer1[1]
        } else if (btn === 2) {
            isRight = question.answer2[1]
        } else {
            isRight = question.answer3[1]
        }
        if (isRight) {
            dispatch(appCurScore())
            if (num===10) {
                dispatch(setActivePanel('game2result'))
            } else {
                setNum(num + 1)
            }
        } else {
            if (num===10) {
                dispatch(setActivePanel('game2result'))
            } else {
                setNum(num + 1)
            }
        }
    }

    let content = []
        if (question.path === '') {
           content.push(
           <div className="scout-game__answers">
            <button id='1' onClick={go} className="scout-answer">{question.answer1[0]}</button>
            <button id='2' onClick={go} className="scout-answer">{question.answer2[0]}</button>
            <button id='3' onClick={go} className="scout-answer">{question.answer3[0]}</button>
        </div>)
        } else {
            content.push(
                <div className="scout-game__video-wrapper">
                    <div className="scout-game__video-block">
                        <img width="325" height="183" src={question.path}></img>
                    </div>
                    <div className="scout-game__answers">
                        <button id='1' onClick={go} className="scout-answer">{question.answer1[0]}</button>
                        <button id='2' onClick={go} className="scout-answer">{question.answer2[0]}</button>
                        <button id='3' onClick={go} className="scout-answer">{question.answer3[0]}</button>
                    </div>
                </div>)
        }

    return (
        <div className="container main-screen scout-game">
            <a href="#" className="main-screen__logo link_disabled">
                <img src="images/escape-logo-white.png" width="208" height="64" alt=""/>
            </a>
            <p className="caption caption_size_big caption_color_white main-screen__caption scout-game__caption">Разведданные</p>
            <p className="scout-game__question">
                {question.text}
            </p>
            {content}
            <button onClick={()=>dispatch(setActivePanel('menu'))} className="button back-button main-screen__back-button">
                назад
            </button>
        </div>
    );
};

export default Game2;