import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const AboutGame1 = () => {
    const dispatch = useDispatch()
    return (
        <div className="container main-screen ava-game">
            <a href="#" className="main-screen__logo link_disabled">
                <img src="images/escape-logo-black.png" width="208" height="64" alt=""/>
            </a>
            <p className="caption caption_size_big caption_color_black ava-game__caption">Твоя Легенда</p>
            <p className="text text_color_black ava-game__description">Какая Легенда подойдет тебе в новом сезоне?
                Пройди сканирование и получи:</p>
            <div className="ava-game__prizes">
                <div className="ava-game__prize">
                    <p>специальную аватарку</p>
                    <img height="135" width="135" src="images/hero1.jpg" alt=""/>
                </div>
                <div className="ava-game__prize">
                    <p>1 медаль</p>
                    <img height="129" width="134" src="images/medal.png" alt=""/>
                </div>
            </div>
            <button onClick={()=>dispatch(setActivePanel('game1'))} className="ava-game__main-button button button_color_red">
                Начать сканирование
            </button>
            <button onClick={()=>dispatch(setActivePanel('menu'))} className="button back-button main-screen__back-button">
                назад
            </button>
        </div>
    );
};

export default AboutGame1;