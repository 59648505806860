import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const Init = () => {

    const dispatch = useDispatch()
    const [isActive, setIsActive] = useState(true)
    let isDisabled = (isActive) ? [true, 'button_deactivated'] : [false, '']


    useEffect(()=>{
        setTimeout(()=> {
            setIsActive(false)
        },3000)
    },[])


    let content = [];

    if (isActive) {
        content.push(<>
        <p className="progerss-bar__caption">загрузка!</p>
        <div className="progress-bar__items">
            <div className="progerss-bar__item"/>
            <div className="progerss-bar__item"/>
            <div className="progerss-bar__item"/>
            <div className="progerss-bar__item"/>
            <div className="progerss-bar__item"/>
        </div></>)
    } else {
        content.push(
            <button
                style={{marginBottom:"10px"}}
            onClick={()=>dispatch(setActivePanel('menu'))}
            className={`button back-button`}>
            Начать
            </button>)
    }

    return (
        <div className="container start-screen">
            <a href="#" className="start-screen__logo link_disabled">
                <img src="images/logo.png" width="237" height="154" alt=""/>
            </a>
            <div className="progerss-bar intro-block__progress-bar">
                {content}
            </div>
        </div>
    );
};

export default Init;