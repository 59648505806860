import React from 'react';
import {setActivePanel} from "../store/mainReducer";
import {useDispatch, useSelector} from "react-redux";

const Menu = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)

    return (
        <div className="container main-screen main-page">
            <a href="#" className="main-screen__logo link_disabled">
                <img src="images/escape-logo-white.png" width="208" height="64" alt=""/>
            </a>
            <p className="caption caption_size_middle caption_color_white main-screen__caption"><span>Сезон Apex Legends “Побег”</span> в <b>самом</b> разгаре!
            </p>
            <p className="text text_color_white main-screen__description">Подбери подходящую Легенду, проверь свои
                знания и меткость,
                чтобы собрать медали <nobr>и побороться</nobr> за призы!</p>
            <div className="games-list main-screen__game-list">
                <button onClick={()=>dispatch(setActivePanel('about1game'))} className="game-preview">
                    <img width="142" height="171" className="game-preview__pic"
                         src="images/ava-game-preview.jpg" alt=""/>
                        <span className="game-preview__title">Твоя Легенда</span>
                </button>
                <button onClick={()=>dispatch(setActivePanel('about2game'))} className="game-preview">
                    <img width="142" height="171" className="game-preview__pic "
                         src="images/scout-game-preview.jpg" alt=""/>
                        <span className="game-preview__title">Разведданные</span>
                </button>
                <button onClick={()=>dispatch(setActivePanel('about3game'))} className="game-preview">
                    <img width="142" height="171" className="game-preview__pic"
                         src="images/shooting-game-preview.jpg" alt=""/>
                    <span className="game-preview__title">Стрельбище</span>
                </button>
            </div>
            <div className="medals main-screen__medals">
                <img className={user.state1 ? "": "medal-deactivated"} width="49" height="49" src="images/medal.png" alt=""/>
                    <img className={user.state2 ? "": "medal-deactivated"} width="49" height="49" src="images/medal.png" alt=""/>
                        <img className={user.state3 ? "" :"medal-deactivated"} width="49" height="49" src="images/medal.png" alt=""/>
            </div>
            <button onClick={()=>dispatch(setActivePanel('prizes'))} className="main-screen__prizes-link prizes-link">Подробнее о наградах</button>
        </div>
    );
};

export default Menu;