import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeStateUser, setActivePanel} from "../store/mainReducer";
import ServerConnect from "../service";
import bridge from "@vkontakte/vk-bridge";

const Game2Res = () => {
    const dispatch = useDispatch()
    const cur_score = useSelector(state=>state.main.cur_score)
    const user = useSelector(state=>state.main.user)
    let rank
    let text

    useEffect(()=>{
        dispatch(changeStateUser('state2'))
        ServerConnect.sendPut('/api/user/'+user.vk_id, {'state2': true, 'score': cur_score})
    },[])

    if (cur_score === 0 || cur_score === 1 || cur_score === 2) {
        rank = 'серебро'
        text = 'Мой путь в сезоне “Побег” только начинается — впереди много открытий!'
    } else if (cur_score === 3 || cur_score === 4) {
        rank = 'платина'
        text = 'Секреты “Побега” постепенно покоряются мне. Врагам лучше считаться со мной!'
    } else if (cur_score === 5 || cur_score === 6) {
        rank = 'алмаз'
        text = 'Моих сведений о сезоне “Побег” достаточно, чтобы превзойти противников!'
    } else if (cur_score === 7 || cur_score === 8) {
        rank = 'мастер'
        text = 'Мне известно почти все о “Побеге”, а в Apex мало кто со мной сравнится!'
    } else if (cur_score === 9 || cur_score === 10) {
        rank = 'высший хищник'
        text = 'Мне известно все о сезоне “Побег”, а на полях Apex Legends мне нет равных!'
    }


    function share() {
        let message = text

        bridge.send("VKWebAppShowWallPostBox",{
            "owner_id": user.vk_id,
            "message": message + '\nПроверь свои знания в Apex и ты — https://vk.com/app8013297' + '\n#ApexLegendsПобег',
            "attachments": "https://vk.com/app8013297",
            "link_button": "open_url",
            "link_image": `https://server1.brandbot24.ru/static/img/${cur_score}_score.png`,
        });
    }

    return (
        <div className="container main-screen scout-game">
            <a href="#" className="main-screen__logo link_disabled">
                <img src="images/escape-logo-white.png" width="208" height="64" alt=""/>
            </a>
            <p className="caption caption_size_big caption_color_white main-screen__caption scout-game__caption">Твой
                результат</p>
            <div className="scout-game__result">
                <p>{`${cur_score}/10`}</p>
                <span>{rank}</span>
            </div>
            <p className="text text_color_white scout-game__success-text">{text}</p>
            <div className="scout-game__buttons">
                <button onClick={share} className="button button_color_red">Поделиться результатом</button>
                <button onClick={()=>dispatch(setActivePanel('about2game'))} className="button button_color_red">Пройти заново</button>
            </div>
            <button onClick={()=>dispatch(setActivePanel('menu'))} className="button back-button main-screen__back-button">
                назад
            </button>
        </div>
    );
};

export default Game2Res;