import React from 'react';
import {setActivePanel} from "../store/mainReducer";
import {useDispatch} from "react-redux";

const AboutGame3 = () => {
    const dispatch = useDispatch()
    return (
            <div className="container main-screen shooting-game">
                <a href="#" className="main-screen__logo link_disabled">
                    <img src="images/escape-logo-white.png" width="208" height="64" alt=""/>
                </a>
                <p className="caption caption_size_big caption_color_white main-screen__caption shooting-game__caption">Стрельбище</p>
                <p className="text text_color_white shooting-game__description">
                    Проверь свою меткость — участвуй в перестрелке <nobr>в <a href='https://vk.com/apexlegends' style={{color: 'rgb(202,64,54)'}} target="_blank"><strong>сообществе</strong></a></nobr>.<br/>
                    15 лучших стрелков получат коды на монеты Apex</p>
                <div className="shooting-game__medal-block medal-block">
                    <p className="text text_color_white">
                        За участие в перестрелке
                        <span>ты получишь 1 медаль</span>
                    </p>
                    <img width="102" height="97" src="images/medal.png" alt=""/>
                </div>
                <button onClick={()=>dispatch(setActivePanel('prizes'))} className="shooting-game__prizes-link prizes-link">Подробнее о призах</button>
                <button className="shooting-game__main-button button button_color_red soon-button">Стрельбище закрыто</button>
                {/*<a href="https://vk.com/wall-55028585_379372" target="_blank" className="shooting-game__main-button button button_color_red">На стрельбище</a>*/}
                <button onClick={()=>dispatch(setActivePanel('menu'))} className="button back-button main-screen__back-button shooting-game__back-button">
                    назад
                </button>
            </div>
    );
};

export default AboutGame3;