import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeStateUser, setActivePanel} from "../store/mainReducer";
import bridge from "@vkontakte/vk-bridge";
import ServerConnect from "../service";

const Game1Res = () => {
    const dispatch = useDispatch()
    const [character, setCharacter] = useState({name:'', path:'', url_share:''})

    const user = useSelector(state=>state.main.user)

    useEffect(()=>{
        dispatch(changeStateUser('state1'))
        ServerConnect.sendPut('/api/user/'+user.vk_id, {'state1': true})
    },[])

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getCharacter() {
            const num = getRandomInt(1, 20)
            let char = characters[num]
            setCharacter(char)
        }

        getCharacter()
    }, [])


    function share() {
        let message = `${character.name} — моя легенда в сезоне @apexlegends (Apex Legends) "Побег"!\nУзнай свою легенду в приложении: https://vk.com/app8013297`

        bridge.send("VKWebAppShowWallPostBox",{
            "message": message,
            "attachments": character.url_share,
        });
    }
    // function share() {
    //     let message = `${character.name} — моя легенда в сезоне @apexlegends (Apex Legends) "Побег"!\nУзнай свою легенду в приложении: https://vk.com/app8013297`
    //
    //     bridge.send("VKWebAppShowWallPostBox",{
    //         "message": message,
    //         "attachments": "photo-55136507199569_941204215889",
    //     });
    // }

    const characters = {
        1 : {name:'Эш', path: 'esh.jpg', url_share: 'photo-209259951_457239018'},
        2 : {name:'Патфайндер', path: 'pathfinder.jpg', url_share:'photo-209259951_457239019'},
        3 : {name:'Бладхаунд', path: 'bloodhaund.jpg', url_share:'photo-209259951_457239020'},
        4 : {name:'Октейн', path: 'octain.jpg', url_share:'photo-209259951_457239021'},
        5 : {name:'Гибралтар',path: 'gibraltar.jpg',url_share:'photo-209259951_457239022'},
        6 : {name:'Мираж', path: 'mirag.jpg',url_share:'photo-209259951_457239023'},
        7 : {name:'Рэйф',path: 'reif.jpg',url_share:'photo-209259951_457239024'},
        8 : {name:'Крипто', path:'cripto.jpg',url_share:'photo-209259951_457239025'},
        9 : {name:'Бангалор', path: 'bangalor.jpg',url_share:'photo-209259951_457239026'},
        10 : {name:'Лайфлайн', path: 'lifeline.jpg',url_share:'photo-209259951_457239027'},
        11 : {name:'Ваттсон', path: 'watson.jpg',url_share:'photo-209259951_457239028'},
        12 : {name:'Каустик', path: 'kaustic.jpg',url_share:'photo-209259951_457239029'},
        13 : {name:'Ревенант', path: 'revenant.jpg',url_share:'photo-209259951_457239030'},
        14 : {name:'Лоба', path: 'loba.jpg',url_share:'photo-209259951_457239031'},
        15 : {name:'Рампарт', path: 'rampart.jpg',url_share:'photo-209259951_457239032'},
        16 : {name:'Хорайзон', path: 'horayzone.jpg',url_share:'photo-209259951_457239033'},
        17 : {name:'Фьюз', path: 'fuiz.jpg',url_share:'photo-209259951_457239034'},
        18 : {name:'Валькирия', path: 'valkira.jpg',url_share:'photo-209259951_457239035'},
        19 : {name:'Сиар', path: 'siar.jpg',url_share:'photo-209259951_457239036'}
    }
    return (
        <div className="container main-screen ava-game">
            <a href="#" className="main-screen__logo link_disabled">
                <img src="images/escape-logo-black.png" width="208" height="64" alt=""/>
            </a>
            <p className="caption caption_size_big caption_color_black ava-game__caption">Твоя Легенда</p>
            <p className="caption caption_size_big caption_color_black ava-game__name">{character.name}</p>
            <img className="ava-game__hero-image" width="142" height="142" src={`images/${character.path}`} alt=""/>
                <div className="ava-game__buttons">
                    <a href={`https://server1.brandbot24.ru/static/img/big_${character.path}`}
                       target="_blank"
                       download={true}
                       className="button button_color_red">Получить аватарку</a>
                    <button onClick={share} className="button button_color_red">Поделиться аватаркой</button>
                </div>
                <p className="ava-game__replay-text">Чтобы получить другую аватарку, пройди сканирование заново</p>
                <button onClick={()=>dispatch(setActivePanel('menu'))} className="button back-button main-screen__back-button">
                    назад
                </button>
        </div>
    );
};

export default Game1Res;