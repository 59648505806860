import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name:'main',
    initialState: {
        activePanel: 'init',
        user: null,
        cur_score:0
    },
    reducers:{
        setActivePanel(state, action) {
               state.activePanel = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        changeStateUser(state,action) {
            if (action.payload === 'state1') {
                state.user.state1 = true
            } else if (action.payload === 'state2') {
                state.user.state2 = true
            }
        },
        appCurScore(state) {
            state.cur_score = state.cur_score + 1
        },
        zeroCurScore(state) {
            state.cur_score = 0
        }
    }
})

export default mainSlice.reducer
export const {setActivePanel,setUser,appCurScore, changeStateUser, zeroCurScore} = mainSlice.actions
